import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts/layout"
import SEO from "../components/seo"

import Container from "@material-ui/core/Container"
import Img from "gatsby-image"

import Grid from "@material-ui/core/Grid"
import Chip from "@material-ui/core/Chip"
import styled from "styled-components"

import HeroButtonPricing from "../components/buttons/hero-button-pricing"
import HeroButtonContact from "../components/buttons/hero-button-contact"
import VerticalLine from "../components/shapes/vertical-line"

import { BlogStyled } from "./styles"

const SideBarContainer = styled.div`
  padding: 20px 30px;
  text-align: center;
  max-width: 350px;
  margin: 0 auto;
  -moz-box-shadow: 0 0 20px #e8e8e8;
  -webkit-box-shadow: 0 0 20px #e8e8e8;
  box-shadow: 0 0 20px #e8e8e8;
  -webkit-transition: all 300ms ease-in;
  border: none;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  position: sticky;
  top: 100px;
`

const SideBar = props => {
  return (
    <Grid item xs={12} sm={4} style={{ position: "sticky", top: 100, left: 0 }}>
      <SideBarContainer>{props.children}</SideBarContainer>
    </Grid>
  )
}

const ProductDescriptionTextContainer = props => {
  return (
    <Grid item xs={12}>
      {props.children}
    </Grid>
  )
}

const ProductDescriptionText = props => {
  return (
    <React.Fragment>
      <BlogStyled
        dangerouslySetInnerHTML={{
          __html: props.post.html,
        }}
        style={{ marginRight: 30, marginTop: 30, lineHeight: 1.5 }}
      />
    </React.Fragment>
  )
}

const Main = props => {
  return (
    <Grid item xs={12} sm={8}>
      {props.children}
    </Grid>
  )
}

const FeatureItem = styled.div`
  background: #fff;
  box-shadow: 0px 12px 33px rgba(0, 12, 64, 0.15);
  padding: 10px;
  margin: 10px;
  font-size: 0.75em;
`

const FeaturesContainer = props => {
  return (
    <Grid container>
      {props.features.map((item, index) => {
        return (
          <Grid item key={index}>
            <FeatureItem>{item}</FeatureItem>
          </Grid>
        )
      })}
    </Grid>
  )
}

class ProductPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { cover } = post.frontmatter
    const coverPath = cover && cover.children[0].fluid.src

    return (
      <Layout>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={coverPath}
        />
        <Container
          maxWidth="lg"
          style={{ marginTop: 100, position: "relative" }}
        >
          <VerticalLine style={{ position: "absolute", top: 0, left: 0 }} />
          <h1>{post.frontmatter.title}</h1>
          <FeaturesContainer features={post.frontmatter.features} />
          <Grid container style={{ marginTop: 20 }}>
            <Main>
              <ProductDescriptionTextContainer>
                <ProductDescriptionText post={post} />
              </ProductDescriptionTextContainer>
            </Main>
            <SideBar>
              <Img fluid={post.frontmatter.cover.children[0].fluid} />
              <div style={{ textAlign: "left" }}>
                <h3>{post.frontmatter.title}</h3>
                <p>Version: {post.frontmatter.version}</p>
                {post.frontmatter.tags.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      color="primary"
                      label={item}
                      style={{ margin: 2 }}
                    />
                  )
                })}
              </div>
              <HeroButtonPricing style={{ margin: "auto", marginTop: 10 }}>
                DOCUMENTATION
              </HeroButtonPricing>
              <HeroButtonContact style={{ margin: "auto", marginTop: 10 }}>
                DOWNLOAD
              </HeroButtonContact>
            </SideBar>
          </Grid>
        </Container>
      </Layout>
    )
  }
}

export default ProductPostTemplate

export const pageQuery = graphql`
  query ProductPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        features
        tags
        version
        cover {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 1200, maxHeight: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
