import styled from "styled-components"

const HeroButtonContact = styled.div`
  display: flex;
  width: 145px;
  height: 51px;
  border-radius: 7px;
  background: linear-gradient(to right, #1e4778 0%, #166ace 100%);
  text-align: center;
  align-items: center;
  justify-content: center;

  font-family: "Fira Sans";
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  color: #fff;

  margin-top: 25px;
  margin-right: 15px;

  cursor: pointer;

  &:hover {
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    transition: all 0.4s ease 0s;
  }
`

export default HeroButtonContact
